import Styled from "vue3-styled-components";

const RecordViewWrapper = Styled.div`
    .btn-add_new{
        ${({ theme }) => (theme.rtl ? "margin-right" : "margin-left")}: 10px;
        a{
            display: flex;
            align-items: center;
            svg,
            img,
            i{
                ${({ theme }) =>
                  !theme.rtl ? "margin-right" : "margin-left"}: 6px;
            }
        }
    }
    .search-box{
        position: relative;
        box-shadow: 0 5px 5px rgba(#9299B8,.3);
        .search-icon{
            position: absolute;
            ${({ theme }) => (theme.rtl ? "right" : "left")}: 18px;
            top: 50%;
            transform: translateY(-50%);
            svg,
            img{
                margin-top: 6px;
                min-width: 16px;
                color: #9299B8;
            }
        }
        input{
            border: 0 none;
            height: 40px;
            min-width: 280px;
            padding: ${({ theme }) =>
              theme.rtl ? "0 45px 0 20px" : "0 20px 0 45px"};
            border-radius: 6px;
            &::placeholder{
                color: #ADB4D2;
            }
            &:focus{
                outline: none;
            }
        }
    }
`;

const RecordFormWrapper = Styled.div`
    .pro-image{
        position: relative;
        margin-bottom: 30px;
        .ant-spin.ant-spin-spinning{
            position: absolute;
            top: 0;
            ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
            width: 120px;
            height: 120px;
            background: #ffffff90;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 10;
            &:after{
                position: absolute;
                ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #272B4120;
                content: '';
                z-index: -1;
            }
            .ant-spin-dot {
                position: relative;
                z-index: 10;
            }
        }
        img{
            max-width: 120px;
            border-radius: 50%;
        }
        .ant-spin{
            height: 120px;
            width: 120px;
            display: flex;
            align-items: center;
        }
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? "right" : "left")}: 80px;
            bottom: -5px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 222;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: #78B943;
            }
        }
        .upload-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        .profile-info{
            ${({ theme }) =>
              theme.rtl ? "margin-right" : "margin-left"}: 20px;
            h1{
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
        .ant-upload-list-item{
            margin-top: 0;
            &:hover{
                .ant-upload-list-item-info{
                    background-color: transparent;
                }
            }
            .ant-upload-list-item-info{
                >span{
                    display: flex;
                    align-items: center;
                    ${({ theme }) =>
                      theme.rtl ? "padding-right" : "padding-left"}: 14px;
                    ${({ theme }) =>
                      !theme.rtl ? "padding-right" : "padding-left"}: 10px;
                }
                .ant-upload-list-item-card-actions {
                    /* // top: -8px; */
                }
            }
        }
    }

    .record-spin{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const PartnerInfo = Styled.div`
  .ant-card-head-title{
    padding: 0 !important;
  }
  .form-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .ant-form-item:not(:last-child){
    margin-bottom: 11px !important;
  }
  .ant-form-item-label{
    line-height: 35px;
  }
  .work-status{
    .ant-form-item-control{
      line-height: 0;
    }
  }
  .ant-form-item-control{
    line-height: 0;
  }
  .add-user-bottom{
    margin-top: 20px;
    button{
      height: 44px;
    }
    button + button{
      ${({ theme }) => (!theme.rtl ? "margin-left" : "margin-right")}: 15px;
    }
    .ant-btn-light{
      background: ${({ theme }) => theme["bg-color-light"]};
      border: 1px solid #F1F2F6;
    }
    &.text-right{
      @media only screen and (max-width: 767px){
        text-align: ${({ theme }) =>
          !theme.rtl ? "left" : "right"} !important;
      }
    }
  }
  .card-nav{
    ul{
      flex-wrap: wrap;
      margin-bottom: -4px -12px;
      @media only screen and (max-width: 575px){
        justify-content: center;
      }
      li{
        margin: 4px 12px !important;
        &:not(:last-child){
          ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 26px;
          @media only screen and (max-width: 575px){
            ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 0;
          }
        }
        a{
          position: relative;
          padding: 22px 0;
          font-size: 14px;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          color: ${({ theme }) => theme["gray-color"]};
          @media only screen and (max-width: 575px){
            padding: 0;
          }
          &:after{
            position: absolute;
            ${({ theme }) => (!theme.rtl ? "left" : "right")}: 0;
            bottom: -4px;
            width: 100%;
            height: 2px;
            border-radius: 4px;
            content: '';
            opacity: 0;
            visibility: hidden;
            background-color: ${({ theme }) => theme["primary-color"]};
            @media only screen and (max-width: 575px){
              display: none;
            }
          }
          &.active{
            color: ${({ theme }) => theme["primary-color"]};
            &:after{
              opacity: 1;
              visibility: visible;
            }
            svg,
            img,
            i,
            span{
              color: ${({ theme }) => theme["primary-color"]};
            }
          }
          svg,
          img,
          i,
          span{
            color: ${({ theme }) => theme["light-color"]};
            ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 7px;
          }
          i svg{
            margin-right: 0;
          }
          i svg{
            margin-right: 0;
          }
        }
      }
    }
  }

  /* // Photo Upload */
  .photo-upload{
    position: relative;
    max-width: 260px;
    margin-bottom: 30px;
    .ant-upload-select{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: absolute;
      ${({ theme }) => (!theme.rtl ? "left" : "right")}: 85px;
      bottom: 5px;
      z-index: 10;
      background-color: ${({ theme }) => theme["white-color"]};
      span{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        z-index: -1;
        background-color: ${({ theme }) => theme["primary-color"]};
      }
      svg,
      i,
      span{
        color: ${({ theme }) => theme["white-color"]};
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    img{
      border-radius: 50%;
    }
    .info{
      background-color: transparent;
    }
    figcaption{
      ${({ theme }) => (theme.rtl ? "margin-right" : "margin-left")}: 20px;
      .info{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .user-work-form{
    .ant-picker{
      padding: 0 15px 0 0;
    }
  }
  .user-info-form{
    .ant-select-single .ant-select-selector .ant-select-selection-item{
      color: ${({ theme }) => theme["gray-color"]};
    }
  }
  .social-form{
    .ant-form-item-control{
      .ant-input-prefix{
        width: 46px;
        height: 46px;
        border-radius: 4px;
      }
      .ant-input-affix-wrapper{
          padding-top: 0 !important;
          padding-bottom: 0 !important;
      }
    }
    .ant-form-item-control-input{
      height: 44px;
      .ant-input-affix-wrapper{
        &:hover,
        &:focus,
        &.ant-input-affix-wrapper-focused{
          border-color: #E3E6EF;
        }
        .ant-input{
          height: 42px;
          ${({ theme }) => (!theme.rtl ? "padding-left" : "padding-right")}: 0;
        }
      }
    }
    .ant-input-affix-wrapper{
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-input-prefix{
      position: relative;
      ${({ theme }) => (!theme.rtl ? "left" : "right")}: -11px;
      ${({ theme }) => (theme.rtl ? "margin-right" : "margin-left")}: 0;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme["primary-color"]};
        i,
        svg,
        span.fa,
        i svg{
          color: #fff !important;
          font-size: 16px;
        }
        &.facebook{
          background-color: #3B5998;
        }
        &.twitter{
          background-color: #38B8FF;
        }
        &.linkedin{
          background-color: #2CAAE1;
        }
        &.instagram{
          background-color: #FF0300;
        }
        &.github{
          background-color: #292929;
        }
        &.youtube{
          background-color: #FE0909;
        }
      }
    }
  }
`;

export { RecordViewWrapper, RecordFormWrapper, PartnerInfo };

<template>
  <sdPageHeader title="Partner Information" />
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <PartnerInfo>
          <sdCards>
            <template #title>
              <div class="card-nav">
                <ul>
                  <li>
                    <a
                      href="#"
                      :class="{ active: selectedTb == 1 }"
                      @click="selectedTb = 1"
                    >
                      <sdFeatherIcons type="user" size="14" />
                      Personal Info
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      :class="{ active: selectedTb == 2 }"
                      @click="selectedTb = 2"
                    >
                      <sdFeatherIcons type="briefcase" size="14" />
                      Bank details
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      :class="{ active: selectedTb == 3 }"
                      @click="selectedTb = 3"
                    >
                      <sdFeatherIcons type="users" size="14" />
                      Users
                    </a>
                  </li>
                </ul>
              </div>
            </template>
            <GeneralInfo
              v-if="partnerApi.data && selectedTb == 1"
            ></GeneralInfo>
            <BankInfo
              :idPartnaire="partnerId"
              v-if="partnerId && selectedTb == 2"
            ></BankInfo>
            <UsersPartner
              :idPartnaire="partnerId"
              v-if="partnerId && selectedTb == 3"
            ></UsersPartner>
          </sdCards>
        </PartnerInfo>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { PartnerInfo } from "./style";
import { onBeforeRouteLeave } from "vue-router";
import { Main } from "../styled";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  computed,
  defineComponent,
  defineAsyncComponent,
  onMounted,
  ref,
} from "vue";
const GeneralInfo = defineAsyncComponent(() =>
  import("./information/GeneralInfo")
);
const BankInfo = defineAsyncComponent(() => import("./information/BankInfo"));
const UsersPartner = defineAsyncComponent(() =>
  import("./information/UsersPartner")
);

const PartnerInfoView = defineComponent({
  name: "PartnerInfoView",
  components: { Main, PartnerInfo, GeneralInfo, BankInfo, UsersPartner },
  setup() {
    const { state, dispatch } = useStore();
    const { matched, params } = useRoute();
    const partnerApi = computed(() => state.partnerApi.data);

    var partnerId = ref("");
    var selectedTb = ref(1);

    onMounted(() => {
      if (params && params.id) {
        partnerId.value = params.id;
        dispatch("getPartnerInfo", partnerId.value);
      }
    });
    onBeforeRouteLeave(() => {
      dispatch("emptyPartnerData");
    });

    return {
      matched,
      partnerId,
      GeneralInfo,
      BankInfo,
      UsersPartner,
      selectedTb,
      partnerApi,
    };
  },
});

export default PartnerInfoView;
</script>
